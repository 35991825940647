'use strict';

module.exports = function($) {
	return {
		init: init
	};

	function init() {
		cancelAnimateOnScroll();
		initToggleClass();
		initAddClass();
		initToggleTarget();
		initCloseOthers();
    initScrollTo();
		initTabs();
		backToTop();
		toggleFreezeBody();
		removeFreezeBody();
    copyText();
    outputRangeValue();
	}

	function cancelAnimateOnScroll() {
		$(window).on('mousewheel', function(){
		  $('html,body').stop();
		});
	}

	function initToggleClass() {

		/*
			Set any class on CLICK
			========================================================
			Allows us to toggle a class on any element via click,
			as defined by data attribute attached to the element
		*/

		$(document).on('click', '[data-toggle-class]', function(event) {
			var $this = $(this);
			var activeClass = $this.data('toggle-class');

			// untoggle any siblings with the active class
			$this.siblings().removeClass(activeClass);

			// toggle the active class
			$this.toggleClass(activeClass).focus();

			// if it's an anchor prevent default
			if ( $this.attr('href') ) {
				event.preventDefault();
			}


			$this.children('[data-toggle-animate]')
				.stop()
				.slideToggle(300);
			$this.siblings()
				.children('[data-toggle-animate]')
				.stop()
				.slideUp(300);

		});

	}


	function initAddClass() {

		/*
			Set any class on CLICK
			========================================================
			Allows us to add a class on any element via click,
			as defined by data attribute attached to the element
		*/

		$(document).on('click', '[data-add-class]', function(event) {
			var $this = $(this);
			var activeClass = $this.data('add-class');

			// untoggle any siblings with the active class
			$this.siblings().removeClass(activeClass);

			// toggle the active class
			$this.addClass(activeClass);

			// if it's an anchor prevent default
			if ( $this.attr('href') ) {
				event.preventDefault();
			}
		});

	}


	function initToggleTarget() {

		/*
			Toggle a target via data attribute
			========================================================
			Toggle .active class of another element on click
		*/


		$(document).on('click', '[data-toggle-target]', function(event) {
			var $this = $(this);
			var toggleTargetName = $this.data('toggle-target');
			var toggleTarget = $('[data-toggle=' + toggleTargetName + ']');

			// toggle the target element
			if (toggleTarget.hasClass('active')) {
				toggleTarget
					.stop(true)
					.removeClass('active')
					.addClass('inactive');
			} else {
				toggleTarget
					.stop(true)
					.addClass('active')
					.removeClass('inactive');
			}



			// if target has data-toggle-animate attribute
			// show it using slideToggle animation
			if ( toggleTarget[0].hasAttribute('data-toggle-animate') ) {
				toggleTarget.stop().slideToggle(400);
			}

			if ( toggleTarget[0].hasAttribute('data-toggle-animate-slow') ) {
				toggleTarget.stop().slideToggle(1200);
			}

      // toggle siblings if data-close-siblings
      if ( $this[0].hasAttribute('data-close-siblings') ) {
				var $siblings = $this.parents('[data-close-siblings-parent]').siblings();

        $siblings
          .find('[data-toggle]')
          .slideUp(300);

        $siblings
          .find('[data-toggle-target]')
          .removeClass('active');
			}

			// make this active
			$this.toggleClass('active');

			// if it's an anchor prevent default
			if ( $this.attr('href') ) {
				event.preventDefault();
				event.stopPropagation();
			}
		});

	}

	function initCloseOthers() {
		var closeOthers = $('[data-close-others]');

		closeOthers.on('click', function() {
			var $this = $(this);
			var closeTargetName = $this.data('close-others');
			var closeTarget = $('[data-toggle=' + closeTargetName + ']');

			closeTarget
				.removeClass('active')
        .addClass('inactive');

		});
	}


	function initScrollTo() {

		/*
			Scroll To
			========================================================
			Use default anchor name behaviour but augment it with a
			bit of JS to scroll the page
		*/

		$(document).on('click', '[data-scroll-to], a[href^="#"]', function(event) {
			if (event) { event.preventDefault(); }
			var $this = $(this);
			var scrollTarget;

			var anchorID 			= $this.attr('href');
			var anchorName 		= anchorID.replace('#','');

			scrollTarget 	= $('a[name*="' + anchorName + '"]');

			if (!scrollTarget.length) {
				scrollTarget 	= $('#' + anchorName);
			}

      if (scrollTarget.length) {
        $('html,body').animate({
          // animate to target
            scrollTop: scrollTarget.offset().top - 120,
            easing: 'easeInOutQuad'
        }, 1250);

        history.replaceState({}, '', anchorID);
      }

		});
	}

	function initTabs() {

		/*
			Tabs
			========================================================
			Allows us to move between sets of tabbed page content
			Finds the data-tab-target attribute, which is used to locate
			the parent <div> of tabbed content.
			Then use the .index position of clicked tab to find the
			tab content child .eq to that index.
		*/

		var tab = $('[data-tab-target] a');

		// swap tabs
		tab.on('click', function(event) {
			event.preventDefault();
			var $this = $(this);

			var thisTab				= $this.parents('li');
			var thisIndex			= thisTab.index();
			var targetName		= thisTab.parent().data('tab-target');
			var targetTabs 		= $('[data-tabs=' + targetName + ']' );
			var targetContent	= targetTabs.children().eq(thisIndex);

			// console.log(targetName);

			// adjust the tab itself
			thisTab.siblings().removeClass('active');
			thisTab.addClass('active');

			// adjust the content
			targetContent.siblings().stop().fadeOut(250);
			targetContent.stop().delay(300).fadeIn(500);
			targetTabs.attr('data-tab-index', thisIndex);

		});

	}

	function backToTop() {
		var scrollLink = $('.js-back-to-top');

		scrollLink.on('click', function(event) {
			if (event) { event.preventDefault(); }
			var offset = $('#content').offset().top;

			$('html,body').animate({
				// animate to top
					scrollTop: offset - 120
			}, 600);

		});
	}

	function toggleFreezeBody() {
		var toggleFreezeBody = $('[data-toggle-freeze-body]');

		toggleFreezeBody.on('click', function() {
			$('body').toggleClass('freeze-body');
		});
	}

	function removeFreezeBody() {
		var removeFreezeBody = $('[data-remove-freeze-body]');

		removeFreezeBody.on('click', function() {
			$('body').removeClass('freeze-body');
		});
	}

  function copyText() {
    $(document).on('click', '[data-copy-text]', function() {
      var $this = $(this);
      var $tempInput = $('<input>');
      $('body').append($tempInput);
      $tempInput.val($this.data('copy-text')).select();
      document.execCommand('copy');
      $tempInput.remove();
    });
  }

  function outputRangeValue() {
    var $range = $('.range-input input[type="range"');
    var $displayElement = $range.parents('.range-input').find('.range-input__counter span');

    $range.on('change', function(event) {
      $displayElement.html(event.target.value);
    });
  }
};
