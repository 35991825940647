'use strict';

module.exports = function($) {
	return {
		init: init
	};

	function init() {
		let message = $('[data-cookie-message]')
		showCookieMessage(message);
	}

	function showCookieMessage(message) {
		if ( !hasCookie() ) {
			dismissMessageButton(message);
			message.addClass('active');
		}
	}

	function hasCookie() {
		return !!document.cookie.split(';').filter((item) => item.includes('emen8-cookies-policy=true')).length;
	}

	function dismissMessageButton(message) {
		let dismissButton = $('[data-dismiss-cookie-message]', message);

		dismissButton.on('click', function() {
			setCookie();
			message.fadeOut(400);
		});
	}

	function setCookie() {
		// set cookie, expiring in 365 days
    	let expiry = new Date();
    	expiry.setDate(expiry.getDate() + 365);
		document.cookie = 'emen8-cookies-policy=true;expires=' + expiry.toGMTString() + '; path=/';
	}
};
