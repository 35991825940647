'use strict';

const grade = window.grade;
const createTableOfContents = require('../content/content_links');

let $postContainer;
let prevPostIds = [];
let nextPostId = null;
let lastReloadedPostId = null;
let shouldUpdateHistory = true;
const loading_html =
  '<div class="loading" style="color: #626A68; padding: 2rem 0 3rem; text-align: center;"><i class="fas fa-spin fa-2x fa-circle-notch" aria-label="Loading"></i></div>';

module.exports = function($) {
	return {
		init: init
	};

	function init() {
    initScrollEvents();
    initPopState();
    $postContainer = $('main.ajax-post-container');
	}

  function initScrollEvents() {
    jQuery('[data-load-next-post]').scrollspy({}, 'destroy');

    $('[data-load-next-post]').each(function eachElement() {
      const $this = jQuery(this);

      // for first load, get ids from the initial post
      if (!nextPostId) {
        nextPostId = $this.data('load-next-post');
      }
      if (!lastReloadedPostId) {
        lastReloadedPostId = $this.data('post-id');
      }

      const position = $this.position();
      const height = $this.height();
      const min = position.top - (window.innerHeight / 4);
      const max = position.top + height - (window.innerHeight / 2);

      $this.scrollspy({
        min: min,
        max: max,
        onEnter: function() {
          onEnterPost($this, 'enter');
        },
        onLeaveTop: function() {
          onEnterPost($this, 'leaveTop');
        },
        onLeaveBottom: function() {
          if (isLastArticle($this) && !hasBeenLoaded(nextPostId) && nextPostId) {
            loadNextPost(nextPostId);
          }
        }
      });
    });
  }

  function initPopState() {
    // disable automatic history scroll restoration
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual';
    }

    // watch for popstate so we can restore scroll position manually
    window.onpopstate = function(event) {
      let scrollY = history.state && history.state.position ? history.state.position : false;

      if(event.isTrusted && history.state) {
        shouldUpdateHistory = false;

        if (!scrollY) {
          scrollY = 0;
        }

        $('html, body').animate({
            scrollTop: scrollY - (window.innerHeight / 4),
            easing: 'easeInOutQuad'
        }, 1250, function() {
          shouldUpdateHistory = true;
        });
      }
    };
  }

	function loadNextPost(id) {
    // console.log('load next post', id);
    // add loading
    $postContainer.append(loading_html);

    const data = {
      action: 'grade_ajax_load_next_post',
      nonce: grade.ajax_post_nonce,
      id: id,
    };

    $.ajax({
      type: 'POST',
      url: grade.ajax_url,
      data,
      success: function(res) {
        if(res.success) {
          $postContainer
            .find('> .loading')
            .fadeOut(400)
            .remove();

          $postContainer
            .append(res.data);

          var lastArticle = $('> article', $postContainer).last();
          // add class to the last article, to aid loading next without dupes
          lastArticle
            .siblings()
            .removeClass('is-last');
          lastArticle
            .addClass('is-last')
            .fadeTo(0, 0)
            .fadeTo(600, 1);

          // keep track of loaded post ids
          prevPostIds.push(id);

          // get the next post id
          nextPostId = $('[data-load-next-post]', lastArticle).data('load-next-post');

          // re-init scrollspy to capture new elements
          initScrollEvents();

          pushGtmEvent($('[data-post-divider]', lastArticle));
        }
      },
      error: function() {
        console.error('failed to load post');
      }
    });
	}

  function onEnterPost($el, event) {
    if (!$el || !shouldUpdateHistory) {
      return;
    }

    if (event === 'enter') {
      // console.log('load entered post data');
      var $article = $el.parent('article');
    }

    if (event === 'leaveTop') {
      // console.log('load previous post data');
      var $article = $el.parent('article').prev('article');
    }

    const id = $('[data-post-id]', $article).data('post-id');

    // only load once, please
    if (id === lastReloadedPostId) {
      // console.log('post is current');
      return;
    }

    lastReloadedPostId = id;

    // update History
    if ($article.length) {
      updateHistory($article);
    }

    // create table of contents
    // only added via WP shortcode
    if ($article.length) {
      createTableOfContents($article);
    }
  }

  function updateHistory($el) {
    var $postDivider = $('[data-post-divider]', $el);
    // var has = '#a' + $postDivider.data('post-divider');
    var permalink = $postDivider.data('permalink');
    var title = $postDivider.data('title');

    // update history via pushstate
    var scrollY = Math.floor($postDivider.position().top);
    history.pushState({ position: scrollY }, title, permalink);

    // console.log('update pushstate', history.state);

    // update title (legacy)
    document.title = title;
    // update canonical (for sharing from browser native share)
    $('link[rel="canonical"]').attr('href', permalink);
    // hide any open sharebox
    $('[data-sharebox]').addClass('inactive').removeClass('active');
  }

  function hasBeenLoaded(id) {
    // console.log('already loaded?', prevPostIds.includes(id), id, prevPostIds);
    return prevPostIds.includes(id);
  }

  function isLastArticle($el) {
    return $el.parent('article').hasClass('is-last');
  }

  function pushGtmEvent($el) {
    if (dataLayer_content) {
      let gtmParams = Object.assign({}, dataLayer_content);

      gtmParams.pageTitle = $el.data('title');
      gtmParams.pageUrl = $el.data('relative-uri');
      gtmParams.event = 'infinitePageView';

      if (window[ gtm4wp_datalayer_name ]) {
        window[ gtm4wp_datalayer_name ].push(gtmParams);
      }
    }
  }
};
