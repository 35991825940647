const $ = require('jquery');

var ageVerification = require('./components/cookies/age');
var cookiesNotification = require('./components/cookies/general');
var helpers = require('./components/helpers/helpers');
var nav = require('./components/nav/nav');
var share = require('./components/share/share');
var tableOfContents = require('./components/content/content_links')

// AJAX functionality
var infinitePosts = require('./components/ajax/infinitePosts');
var knowledgeTopics = require('./components/ajax/knowledgeTopics');
var filterSearchResults = require('./components/ajax/filterSearchResults');

// Google Maps
var map = require('./components/map/index');
map($).init();

export default {
  init() {
    ageVerification($).init();
    cookiesNotification($).init();
    helpers($).init();
    nav($).init();
    share($).init();
    tableOfContents();
    // AJAX
    infinitePosts($).init();
    knowledgeTopics($).init();
    filterSearchResults($).init();
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
