'use strict';

module.exports = function($) {
	return {
		init: init
	};

	function init() {
		initToggleSharing()
	}

	function initToggleSharing() {
		$(document).on('touchend', '[data-toggle-sharebox]', function() {
      // use native sharing
      if (navigator.share) {
        const title = $(this).data('share-title');
        const url = $(this).data('share-url');

        navigator.share({
          title: title,
          url: url,
        }).then(() => {
          // shared
        })
        .catch(console.error);
      } else {
        const $this = $(this);
        showShareDialog($this);
      }
		});

    $(document).on('mouseup', '[data-toggle-sharebox]', function() {
      const $this = $(this);
      showShareDialog($this);
		});
	}

  function showShareDialog($this) {
    const target = $this.data('toggle-sharebox');
    const shareBox = $('[data-sharebox=' + target + ']');

    // toggle the target element
    if (shareBox.hasClass('active')) {
      shareBox
        .removeClass('active')
        .addClass('inactive');
    } else {
      shareBox
        .addClass('active')
        .removeClass('inactive');
    }
  }

};
