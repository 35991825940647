'use strict';

const debounce = require('./debounce');
const grade = window.grade;
let $postContainer = null;
let previousTopic = null;
let currentTopic = null;

module.exports = function($) {
	return {
		init: init
	};

	function init() {
    debounceFilter();
    $postContainer = $('.ajax-search-result-container');
	}

	function debounceFilter() {
    const doFilter = debounce(filterSearchResults, 333);
    $('[data-filter-search-post-type]').on('click', doFilter);
	}

  function filterSearchResults() {
    const $this = $(this);
    const type = $(this).data('filter-search-post-type');
    const searchTerm = $(this).data('search-term');

    currentTopic = type

    if (currentTopic !== previousTopic) {
      const data = {
        action: 'grade_ajax_filter_search_results',
        nonce: grade.ajax_post_nonce,
        type: type,
        searchTerm: searchTerm
      };

      $.ajax({
        type: 'POST',
        url: grade.ajax_url,
        data,
        success: function(res) {
          if(res.success) {
            $postContainer.html(res.data);

            const $cards = $postContainer.find('.card');

            $cards.each( function(index) {
              $(this)
                .fadeTo(0, 0)
                .delay(index * 100)
                .fadeTo(400, 1);
            });

            // button classes
            $this.stop().addClass('active');
            $this.stop().siblings().removeClass('active');

            // update tracked topic
            previousTopic = currentTopic
          }
        },
        error: function(xhr) {
          //
        }
      });
    }
  }
};
