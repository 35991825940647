'use strict';

const MarkerClusterer = require('node-js-marker-clusterer');

module.exports = function($) {
	var map;
	var center;
	var bounds;
	var markers = [];
  var markerCluster;
	var locations;
  var referenceMarker;
	var mapElement = null;
	var grade = window.grade;

	return {
		init: init
	};

	function init() {
		window.initServicesMap = initServicesMap;
	}


	// init

	function initServicesMap() {
		var google = window.google;
		mapElement = document.getElementById('map');

		// create map if element exists
		if ( typeof(mapElement) !== 'undefined' && mapElement !== null ) {
			locations = window.map_data;
      referenceMarker = window.reference_marker;
			bounds = new google.maps.LatLngBounds();

			var mapOptions = {
				// map center
				center: new google.maps.LatLng(-32.7417568,145.5634478),
				zoom: 6,
				zoomControlOptions: {
					style: google.maps.ZoomControlStyle.SMALL,
					position: google.maps.ControlPosition.RIGHT_TOP
				},
				maxZoom: 16,
				mapTypeControl: false,
				disableDoubleClickZoom: false,
				draggable : true,
				scaleControl: false,
				scrollwheel: false,
				panControl: false,
				streetViewControl: false,
				overviewMapControl: false,
				zoomControl: true,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				styles : [
					{
						'stylers': [
							{'weight':'0'}
						]
					},
					{'featureType':'poi.park','elementType':'geometry.fill','stylers':
						[
							{'color':'#00AEDA'},
              {'gamma':'4'},
              { 'saturation': '-30' }
						]
					},
          {'featureType':'landscape','elementType':'geometry.fill','stylers':
						[
							{'color':'#00AEDA'},
              {'gamma':'6'},
              { 'saturation': '-50' }
						]
					},
          {
            'featureType':'landscape.man_made','elementType':'all','stylers':
						[
							{'color':'#FDB53C'},
							{'gamma':'7'},
              { 'saturation': '-30' }
						]
					},
					{'featureType':'road','elementType':'all','stylers':
						[
							{'hue':'#FDB53C'}
						]
					},
					{'featureType':'road.arterial','elementType':'geometry.fill','stylers':
						[
              {'color':'#FDB53C'},
							{'lightness':'0'},
							{'gamma':'2'}
						]
					},
					{'featureType':'water',
            'elementType':'all',
            'stylers':
						[
							{'color':'#6FC5E8'}
						]
					},
					{'featureType':'transit','stylers':
						[
							{'visibility':'off'}
					  ]
          },
          {
            'featureType': 'poi',
            'elementType': 'all',
            'stylers':
            [
							{'visibility':'off'}
					  ]
          }
				]
			};

			map = new google.maps.Map(mapElement, mapOptions);

			// global infoWindow (so we can show one at a time)
			var infoWindow = new google.maps.InfoWindow();
			var markerIcon 	= grade.theme_url + '/assets/img/marker.svg';
      var referenceMarkerIcon 	= grade.theme_url + '/assets/img/reference-marker.svg';
			var clusterIcon 	= grade.theme_url + '/assets/img/cluster.svg';

			// iterate over data
			const createMarkers = locations.map(function(location) {

				var markerPosition = new google.maps.LatLng(location.lat, location.lng);

				var marker = new google.maps.Marker({
					position: markerPosition,
					anchorPoint: new google.maps.Point(0, -20),
					icon: {
						url: markerIcon,
						scaledSize: new google.maps.Size(90, 60),
						anchor: new google.maps.Point(45, 30)
					},
					map: map,
          id: location.id,
          categories: location.categories,
					name: location.name,
					address: location.address,
          lat: location.lat,
          lng: location.lng,
					phone: location.phone,
					email: location.email,
					website: location.website,
				});

				// add to markers array;
				markers.push(marker);

				bounds.extend(markerPosition);
				fitBounds();

				// set up infoWindow
				bindInfoWindow(
					marker,
					map
				);
			});

      // Search location marker
      if (referenceMarker.lat && referenceMarker.lng) {
        var referenceMarkerPosition = new google.maps.LatLng(referenceMarker.lat, referenceMarker.lng);

        new google.maps.Marker({
          position: referenceMarkerPosition,
          anchorPoint: new google.maps.Point(0, -20),
          zIndex: -999999,
          icon: {
            url: referenceMarkerIcon,
            scaledSize: new google.maps.Size(90, 60),
            anchor: new google.maps.Point(45, 30)
          },
          map: map,

        });

        // bounds.extend(referenceMarkerPosition);
        // fitBounds();
      }


			// create clusters of markers for better UX
			// options
			var clusterOptions = {
				maxZoom: 12,
				gridSize: 40,
				averageCenter: true,
				cssClass: 'markerCluster',
				styles: [
					{
						textColor: 'white',
						textSize: 16,
						url: clusterIcon,
						height: 80,
						width: 80,
						iconAnchor:[50,50],
						backgroundPosition: '0% 0%'
					}
				]
			};
			// init clusters
			markerCluster = new MarkerClusterer(map, markers, clusterOptions);
		}

		function bindInfoWindow(marker, map) {

			if ( typeof(map) !== 'undefined' && map !== null ) {
				// open on click (replaces other open infoWindow)
				google.maps.event.addListener(marker, 'click', function () {
					// infoWindow content
					var content;
					content = '<div class="iw-content" data-service-id="' + marker.id + '">';
					content += '<h3>' + marker.name + '</h3>';

					content += '<ul>';
          if ( marker.address && marker.address !== 'undefined' && marker.address !== null ) {
						content += '<li>';
            content += '<i class="icon fas fa-fw fa-map-marker-alt" aria-hidden></i> ';
            content += '<a  href="https://www.google.com/maps/dir/?api=1&destination=' + marker.lat + ',' + marker.lng + '&travelmode=driving" target="_blank" aria-label="Get Directions to ' + marker.name + '">';
            for (var i = 0; i < marker.address.length; i++) {
              content += marker.address[i];
              if (i < marker.address.length - 1) {
                content += ', ';
              }
            }
            content += '<span class="directions">Get Directions</span>';
            content += '</a>';
            content += '</li>';
					}
					if ( marker.phone && marker.phone !== 'undefined' && marker.phone !== null ) {
						content += '<li>';
            content += '<i class="icon fas fa-fw fa-phone-alt" aria-hidden></i> ';
            content += '<a href="tel:' + marker.phone + '" aria-label="Contact service by phone">' + marker.phone + '</a>';
            content += '</li>';
					}
					if ( marker.email && marker.email !== 'undefined' && marker.email !== null ) {
						content += '<li>';
            content += '<i class="icon far fa-fw fa-envelope" aria-hidden></i> ';
            content += '<a href="mailto:' + marker.email + '" aria-label="Contact service by email">' + marker.email + '</a>';
            content += '</li>';
					}
					if ( marker.website && marker.website !== 'undefined' && marker.website !== null ) {
						content += '<li class="website">';
            content += '<i class="icon fas fa-fw fa-external-link-alt" aria-hidden></i> ';
            content += '<a href="' + marker.website + '" target="_blank" aria-label="Visit this service\'s website">' + marker.website + '</a>';
            content += '</li>';
					}
          if ( marker.categories && marker.categories !== 'undefined' && marker.categories !== null ) {
            content += '<li>';
            content += '<i class="icon fas fa-fw fa-tag" aria-hidden></i> ';
            content += '<div>';
            for (var i = 0; i < marker.categories.length; i++) {
              content += '<span class="tag"><i class="fas fa-check"></i> ' + marker.categories[i].name + '</span>';
            }
            content += '</div>';
            content += '</li>';
          }
					content += '</ul>';
					content += '</div>';

					infoWindow.setContent(content);
					infoWindow.open(map, marker);
				});
			}

			if ( typeof(map) !== 'undefined' && map !== null ) {
				map.addListener('idle', function() {
					calculateCenter();
				});
				google.maps.event.addDomListener(window, 'resize', function() {
					map.setCenter(center);
				});

        // Close IW
        google.maps.event.addListener(map, 'click', function() {
					infoWindow.close();
				});
			}

		}


		function fitBounds() {
			// reset center on resize
		  center = map.fitBounds(bounds);
		}

		function calculateCenter() {
			center = map.getCenter();
		}
	}
};
