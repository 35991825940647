'use strict';

const debounce = require('./debounce');
const grade = window.grade;
let $postContainer = null;
let previousTopic = null;
let currentTopic = null;

module.exports = function($) {
	return {
		init: init
	};

	function init() {
    debounceFilter();
    $postContainer = $('.ajax-knowledge-container');
	}

  function debounceFilter() {
    const doFilter = debounce(filterTaxonomy, 333);
    $('[data-knowledge-topic]').on('click', doFilter);
	}

	function filterTaxonomy() {
    const $this = $(this);
    const topic = $(this).data('knowledge-topic');

    currentTopic = topic

    if (currentTopic !== previousTopic) {
      let $cards = $postContainer.find('.card, header.alpha-title');

      $cards.fadeTo(600, 0);

      const data = {
        action: 'grade_ajax_filter_knowledge_topics',
        nonce: grade.ajax_post_nonce,
        slug: topic
      };

      $.ajax({
        type: 'POST',
        url: grade.ajax_url,
        data,
        success: function(res) {
          if(res.success) {
            $postContainer.html(res.data);

            $cards = $postContainer.find('.card, header.alpha-title');

            $cards.each( function(index) {
              $(this)
                .fadeTo(0, 0)
                .delay(index * 100)
                .fadeTo(400, 1);
            });

            // button classes
            $this.addClass('active');
            $this.siblings().removeClass('active');

            // update tracked topic
            previousTopic = currentTopic
          }
        },
        error: function(xhr) {
          //
        }
      });
    }
	}
};
