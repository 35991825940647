'use strict';

module.exports = function($) {
	return {
		init: init
	};

	function init() {
		setAgeVerification();
    setAgeOption();
	}

	function setAgeVerification() {
		var ageVerificationElement = $('[data-age-verification-select]');

    showCookieMessage(ageVerificationElement);
	}

  function setAgeOption() {
    $(document).on('click', '[data-confirm-age]', function(event) {
      var ageVerificationElement = $('[data-age-verification-select]');
      var responseValue = $(this).data('confirm-age');

      if (responseValue || responseValue === 'true' ) {
        event.preventDefault();
      }

      setCookie('emen8_age_verification', responseValue);

      // hide age verification
      ageVerificationElement.removeClass('active');

      // then remove from DOM
      setTimeout(function() {
        ageVerificationElement.remove();
      }, 650);

      // let body scroll
      $('body').removeClass('freeze-body-always');
    });
  }


  function showCookieMessage(message) {
		if ( !hasCookie() ) {
			message.addClass('active');
		}
	}

	function hasCookie() {
		return !!document.cookie.split(';').filter((item) => item.includes('emen8_age_verification=true')).length;
	}

	function setCookie(name, value) {
		// set cookie, expiring in 30 days
		// console.log(name, value);
  	var expiry = new Date();
  	expiry.setDate(expiry.getDate() + 30);
		document.cookie = name + '=' + value + ';expires=' + expiry.toGMTString() + '; path=/';
	}
};
