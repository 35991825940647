'use strict';

const Headroom = require('headroom.js');

module.exports = function($) {
	var navOpen = false;
	var currentNavName = null;
	var previousNavName = null;
	var nav = null;
	var isChildNav = false;
  var isLanguageNav = false;
  var isSearch = false;
  var isSubscribe = false;

	return {
		init: init
	};

	function init() {
		launchNav();
		stickyNav();
	}

	function stickyNav() {
		// grab an element
		var navigation = document.querySelector('header#primary');
		// construct an instance of Headroom, passing the element
		var options = {
	    offset: 120
		};

		var headroom  = new Headroom(navigation, options);
		// initialise
		headroom.init();
	}

	/*
		Menu Systems
		========================================================
		Open & Close various nav elements
		and toggle relevant classes
	*/

	function launchNav() {
		var navButtons = $('[data-toggle-nav]');
		var allNavs = $('[data-nav]');

		navButtons.bind('click touchend', function(event) {
			var $this = $(this);
			var parent = $this;
      isLanguageNav = false;
      isSearch = false;
      isSubscribe = false;

			previousNavName = currentNavName;
			currentNavName = $this.data('toggle-nav');

			// target nav elements
			nav = $('[data-nav="' + currentNavName + '"]');


			// is this the primary nav button?
			var isPrimaryNavButton = currentNavName === 'main';
      // is it the language nav?
      isLanguageNav = currentNavName === 'language';
      // is it the search nav?
      isSearch = currentNavName === 'search';
      // is it the subscribe nav?
      isSubscribe = currentNavName === 'subscribe';

			// is it the child nav?
			var navDepth = nav.data('nav-depth');
			isChildNav = navDepth >= 0 ? true : false;


			if ( nav.length ) {
				// if it's a child nav
				if (
					navOpen &&
					isPrimaryNavButton
				) {
					closeAllNavs(navButtons, allNavs);
				}

				// if there's a new nav to open
				else {

					// open or close nav item
					if (
						navOpen &&
						previousNavName !== null &&
						previousNavName === currentNavName
					) {
						// close chosen nav on repeat click
            if (!isSearch && !isLanguageNav && !isSubscribe) {
              closeNav(nav, parent);
            } else {
              closeAllNavs(navButtons, allNavs);
            }
					}

					else {
						// open chosen nav
						openNav(nav, parent);
					}
				}

				// close any open previous nav
				if (
					!isChildNav &&
					previousNavName !== null &&
					previousNavName !== currentNavName
				) {

					var previousNav = $('[data-nav="' + previousNavName + '"]');
					var previousNavParent = $('[data-toggle-nav="' + previousNavName + '"]');

					if (
						previousNav.length &&
						previousNavParent.length &&
						previousNavName !== 'main'
					) {
						closeNav(previousNav, previousNavParent);
					}
					// open chosen nav
					openNav(nav, parent);
				}
      }

			if ( $this.attr('href') ) {
				event.preventDefault();
			}

			return false;

		});
	}

	// open specific Nav
	function openNav(nav, parent) {
		navOpen = true;
		parent.addClass('active');

		nav
			.removeClass('inactive')
			.addClass('active').focus();

		if (isChildNav) {
			$('body').addClass('sub-nav-visible');
		}

    if (isLanguageNav) {
			$('body').addClass('language-nav-visible');
		}

		if (isSearch || isSubscribe) {
			// nav.find('input[type="text"]').focus();
      nav.find('input').first().focus();
		}

    if (!isSearch && !isLanguageNav && !isSubscribe) {
			setNavBodyState();
		}
	}

	// close specific open Nav
	function closeNav(nav, parent) {
		parent
			.removeClass('active');
		nav
			.removeClass('active')
			.addClass('inactive');

    resetNavBodyState();
	}

	// close all open Navs
	function closeAllNavs(buttons, navs) {
		buttons
			.removeClass('active');
		navs
			.removeClass('active')
			.addClass('inactive');

		resetNavBodyState();
	}

	function setNavBodyState() {
		var scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop();
		var bodyHeight = $('html').innerHeight();

		$('body')
			.addClass('freeze-body nav-menu-visible show-nav-menu--primary')
			.css('top', -scrollTop)
			.css('height', bodyHeight + 'px');
	}

	function resetNavBodyState() {
		// set body scroll
		var scrollTop = parseInt($('body').css('top'));
		$('body')
			.removeClass('freeze-body nav-menu-visible sub-nav-visible show-nav-menu--primary')
			.css('height', 'auto')
			.scrollTop(-scrollTop);

		navOpen = false;
	}
};
