const $ = require('jquery');

const createTableOfContents = function($article) {
  if (!$article) {
    $article = $('article.is-last');
  }
  const $el = $('[data-toc]', $article).first();
  const $list = $el.find('.toc-list');

  if ($list && $list[0] && $list[0].childElementCount === 0) {
    const $headings = $('.content--article', $article).find('h2[id]');

    $headings.each(function () {
      $list.append($(`<li><a data-scroll-to href="#${$(this).attr('id')}">${$(this).text()}</a></li>`));
    });

    $el.stop().slideDown(1200);
  }
};

module.exports = createTableOfContents;
